import { Component, Vue, Prop } from 'vue-property-decorator';
import { accountServices } from '../services/accountService';
import { validatePassword } from '@/utils/validate';

@Component({})
export default class ResetPassword extends Vue {
  @Prop()
  userId: string;

  @Prop()
  code: string;

  loading = false;
  errors = '';
  manage = {} as account.resetPassword;

  showPassword = {
    password: false,
    confirm: false
  };
  
  get validationRoles() {
    return {
      password: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(`${this.$t('resetPassword.required.password')}`));
            } else if (!validatePassword(value)) {
              callback(new Error(`${this.$t('resetPassword.required.passwordPattern')}`));
            } else {
              if (this.manage.confirmPassword !== '') {
                (this.$refs.resetPassword as any).validateField('confirmPassword');
              }
              callback();
            }
          },
          trigger: 'blur'
        }
      ],
      confirmPassword: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(`${this.$t('resetPassword.required.confirmPassword')}`));
            } else if (value !== this.manage.password) {
              callback(new Error(`${this.$t('resetPassword.required.confirmPasswordMatch')}`));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
      ]
    }
  }

  public async reset() {
    await (this.$refs.resetPassword as any).validate(async valid => {
      this.loading = true;
      if (valid) {
        accountServices.ResetPassword(this.userId, this.code, this.manage.password).then(
          () => {
            this.$message({
              message: `<strong>${this.$t('resetPassword.success')}</strong><br>${this.$t('resetPassword.successRedirect')}`,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'success',
              duration: 5000
            });
            this.loading = false;
            setTimeout(() => {
              this.backToLogin();
            }, 1000);
          },
          err => {
            if (err && err.exceptionMessage) {
              this.errors = err.exceptionMessage;
            } else if (err && err.response && err.response.exceptionMessage) {
              this.errors = err.response.exceptionMessage;
            } else if (err && err.response && err.response.data && err.response.data.length > 0) {
              this.errors = (err.response.data as string[]).reduce((a, b) => `${a}, ${b}`);
            }

            let _message = `${this.$t('resetPassword.error')}`;
            if (this.errors) {
              _message += `<br><i>${this.errors}</i>`;
            }

            this.$message({
              message: _message,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 5000
            });
            this.loading = false;
          }
        );
      } else {
        this.loading = false;
      }
    });
  }

  public backToLogin() {
    this.$router.push({ path: '/login' });
  }
}
